<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Assign User Hours</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="ml-2">
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Monday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.monday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Tuesday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.tuesday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Wednesday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.wednesday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Thursday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.thursday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Friday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.friday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Saturday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.saturday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="4" class="mt-7 ml-5"
                        ><strong>Sunday: </strong></v-col
                    >
                    <v-col cols="3" class="mr-0">
                        <v-text-field
                            v-model="userWeekHours.sunday"
                            hide-details="auto"
                            required
                            :rules="[rules.number, rules.number2]"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="save"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'AssingEngineer',
    props: {
        user: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            required: v => v || '0' || 'The param is required',
            number: v => ((v || '0') && v >= 0) || 'Invalid number',
            number2: v => ((v || '0') && v <= 10) || 'Invalid number',
        },
        userWeekHours: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
        },
    }),
    mounted() {
        if (this.user.userWeekHours) {
            this.userWeekHours = {
                ...this.userWeekHours,
                ...this.user.userWeekHours,
            }
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                await API.updateEngineeringHours(this.user.id, {
                    userWeekHours: {
                        monday: Number(this.userWeekHours.monday)
                            ? Number(this.userWeekHours.monday)
                            : 0,
                        tuesday: Number(this.userWeekHours.tuesday)
                            ? Number(this.userWeekHours.tuesday)
                            : 0,
                        wednesday: Number(this.userWeekHours.wednesday)
                            ? Number(this.userWeekHours.wednesday)
                            : 0,
                        thursday: Number(this.userWeekHours.thursday)
                            ? Number(this.userWeekHours.thursday)
                            : 0,
                        friday: Number(this.userWeekHours.friday)
                            ? Number(this.userWeekHours.friday)
                            : 0,
                        saturday: Number(this.userWeekHours.saturday)
                            ? Number(this.userWeekHours.saturday)
                            : 0,
                        sunday: Number(this.userWeekHours.sunday)
                            ? Number(this.userWeekHours.sunday)
                            : 0,
                    },
                })

                this.$emit('updateUserHours', {
                    userId: this.user.id,
                    userWeekHours: {
                        monday: Number(this.userWeekHours.monday) || 0,
                        tuesday: Number(this.userWeekHours.tuesday) || 0,
                        wednesday: Number(this.userWeekHours.wednesday) || 0,
                        thursday: Number(this.userWeekHours.thursday) || 0,
                        friday: Number(this.userWeekHours.friday) || 0,
                        saturday: Number(this.userWeekHours.saturday) || 0,
                        sunday: Number(this.userWeekHours.sunday) || 0,
                    },
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
